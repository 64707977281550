<template>
  <div class="bg">
    <div class="title">
      胃痛的症状几种表现，千万要引起注意胃痛的症状几种表现，千万要引起注意
    </div>
    <div class="author">
        <img src="../../assets/img/inquiry_10.png" alt="">
        <div>
          <p>林清玄</p>
          <span>
            # 慢病调理
          </span>
        </div>
    </div>
    <div v-html="info" class="myhtml">
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info:'<h1>asdasdasd</h1>'
    }
  },
}
</script>
<style lang="less" scoped>
.bg {
  background: #fff;
  padding: 16px;
     text-align: left;
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #2d2d2d;
    margin-bottom: 24px;
  }
  .author{
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 16px;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
      }
      p{
        color: #2d2d2d;
        margin: 4px 0;
      }
      span{
        color: #1daefc;
        font-size: 12px;
      }
  }
  .myhtml{
    img{
      max-width: 343px;
      max-height: 205px;
    }
  }
}
</style>
